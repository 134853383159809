html,
body,
main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit
}

.turnImg {
  transform: rotate(180deg);
}
