.dropzone {
  height: 12.5vh;
  width: 100%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 0;
}

.dropzone input {
  display: none;
}

.dropzone span {
  text-align: center;
}

.dropzone.highlight {
  border: 5px dashed;
  opacity: 0.5;
}

.dropzone .icon {
  opacity: 0.3;
  height: 4rem;
  width: 4rem;
}

.progress-bar {
  width: 50vw;
  height: 1rem;
  border-radius: 8px;
  margin: 0.5rem 0;
}

.progress-bar>div {
  height: 100%;
  margin: 0;
  border-radius: 8px;
}

.show-link:hover {
  cursor: pointer;
}